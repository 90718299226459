<div class="container pt-5">
  <div class="row container d-flex justify-content-center">
    <div class="col-md-12">
      <div class="card px-3">
        <div class="card-body">
          <h4 class="card-title">Awesome Todo list</h4>
          <app-add-task></app-add-task>
          <div class="pt-3">

            <div class="card" *ngFor="let item of items | async">
              <div class="card-body">
                <div class="justify-content-between d-flex align-items-center">
                  <p class="p-0 m-0">{{item.name}}</p>
                  <div class="d-flex align-items-center">
                    <button (click)="delete(item.id)" class="btn btn-danger ms-2">
                      <i class="fas fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
